<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Create Attribute</p>
          </div>
          <div class="col-12 col-xl-6 text-right ">
            <button
              type="button"
              class="btn btn-sm bt-main  px-3"
              @click="goto()"
            >
              Back
            </button>
          </div>
        </div>
        <!-- ------------------------ -->

        <div class="row m-0 p-0">
          <AddUpdateAttribute />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddUpdateAttribute from "@/components/Manage/Attributes/AddUpdateAttribute.vue";
export default {
  name: "AttributesCreate",
  components: { AddUpdateAttribute },
  data() {
    return {
      reModalcatcode: Date.now() + 5,
      mode_edit: false,
     
    };
  },
  watch: {
    
  },
  mounted() {
    
  },
  methods: {
     

    async goto() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
      );
      if (confirm) {
        this.$router.push({ name: "Attributes" });
      }
    },
 
 
  },
  // async beforeRouteLeave(to, from, next) {
  //   // const answer = window.confirm(
  //   //   "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
  //   // );
  //   let confirm = await this.$serviceMain.showConfirmAlert(
  //     this,
  //     "คุณต้องการที่ออกหน้านี้? คุณมีการเปลี่ยนแปลงที่ยังไม่ได้บันทึก! / Do you really want to leave? you have unsaved changes!"
  //   );
  //   if (confirm) {
  //     next();
  //   } else {
  //     next(false);
  //   }
  // },
};
</script>

<style></style>
